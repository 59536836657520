import { defineStore } from 'pinia'
import repository from '../api/repository'

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      isFristTime: true,
      userList: null,
      blackListData: 
        {
          
          page: 1,
          size: 10,
          data: [],
          previous: 0,
          next: 0 
        }
      ,
      user: null,
      isUsersUpdated: true,
      isUserUpdated: true,
      admin: null,
      isUserHasMoreData: true,
      totalPages: null,
      totalCount: null,
      currentPage: 1,
      searchValue: ''
    }
  },
  getters: {
    whatsappUsers: (state) => {
      return state.userList ? state.userList['whatsapp'] : []
    },
    instagramUsers: (state) => {
      console.log('instagramUsers', state.userList['instagram'])
      return state.userList ? state.userList['instagram'] : []
    },
    filterUsers: (state) => {
      return (platform) => (state.userList ? state.userList[platform] : [])
    },
    gerUsersAddedFrom: (state) => {
      console.log('usersAddedFrom')
      return (from) => {
        console.log({
          userList: state.userList,
          from,
        })
        if (state.userList) {
          if (from === 'all') return state.userList['whatsapp']
          else return state.userList['whatsapp'].filter((user) => user.added_from === from)
        } else {
          return []
        }
      }
    },
    blackList: (state) => {
      return state.blackListData;
    },
    _totalPages: (state) => {
      return state.totalPages;
    },
    _totalCount: (state) => {
      return state.totalCount;
    },
    _currentPage: (state) => {
      return state.currentPage;
    },
    getSearchValue: (state) => {
      return state.search;
    },
  },
  actions: {
    async getUsersWithPagination( limit = 100) {
      // if (!this.isUsersUpdated) {
        // console.log('Users already Loaded')
      // } else {
        if(this.isFristTime || this.currentPage < this.totalPages){
          const res = await repository.paginate('users', this.currentPage, limit, this.searchValue);
          this.isUsersUpdated = false
          this.isFristTime  = false
          res.data.data.users['whatsapp'].sort((a, b) => {
            console.log(a.messages[0], b.messages[0])
            let da, db
            if (a.messages[0]) da = new Date(a.messages[0].createdAt)
            else da = 0
  
            if (b.messages[0]) db = new Date(b.messages[0].createdAt)
            else db = 0
            // let da = new Date(a.messages[0] ? a.messages[0].createdAt : ''),
            //   db = new Date(b.messages[0] ? b.messages[0].createdAt : '');
            return db - da
          })
          if (this.user && this.userList) {
            const idx = this.userList
              ? this.userList['whatsapp'].findIndex((x) => x.id == this.user.id)
              : null
  
            this.userList['whatsapp'][idx]['messages'] = [
              this.user.messages[this.user.messages.length - 1],
            ]
          }
          console.log({
            data: res?.data?.data,
            userList: this.userList,
          });

          if(this.userList){
            this.userList['whatsapp'].push(...res.data.data.users['whatsapp']);
          }else{
            this.userList = res.data.data.users
          }
          // this.userList != null ? this.userList.push(res.data.data.users) : 
          this.totalCount = res?.data?.data?.totalCount;
          this.totalPages = res?.data?.data?.totalPages;
          this.currentPage += 1;
  
  
          // this.admin = res.data.data.users.admin
       
        // }
       
      }
    },
    async getUsers() {
      if (!this.isUsersUpdated) {
        console.log('Users already Loaded')
      } else {
        const res = await repository.index('users')
        res.data.data.users['whatsapp'].sort((a, b) => {
          console.log(a.messages[0], b.messages[0])
          let da, db
          if (a.messages[0]) da = new Date(a.messages[0].createdAt)
          else da = 0

          if (b.messages[0]) db = new Date(b.messages[0].createdAt)
          else db = 0
          // let da = new Date(a.messages[0] ? a.messages[0].createdAt : ''),
          //   db = new Date(b.messages[0] ? b.messages[0].createdAt : '');
          return db - da
        })
        if (this.user && this.userList) {
          const idx = this.userList
            ? this.userList['whatsapp'].findIndex((x) => x.id == this.user.id)
            : null

          this.userList['whatsapp'][idx]['messages'] = [
            this.user.messages[this.user.messages.length - 1],
          ]
        }
        this.userList = res.data.data.users
        // this.admin = res.data.data.users.admin
        this.isUsersUpdated = false
      }
    },
    async getBlackList(page = 1, limit = 8) {
      // if (this.blackListData.data.length > 0) {
      //   console.log('blackList already Loaded')
      // } else {
        const res = await repository.index('users/black-list', true, page, limit);
        console.log({
          res
        })
        this.blackListData.data = res.data.data;
        this.blackListData.page = res.data.page;
        this.blackListData.size = res.data.size;
        this.blackListData.previous = res.data.previous;
        this.blackListData.next = res.data.next; 
      // }
    },
    async deleteFromBlackList(id) {
      // if (this.blackListData.data.length > 0) {
      //   console.log('blackList already Loaded')
      // } else {
        const res = await repository.destroy('users/black-list', id);
        console.log({
          res
        })
        if(res.data.success){
          const idx = this.blackListData.data.findIndex((x) => x._id == id);
          if (idx > -1) { // only splice array when item is found
            this.blackListData.data.splice(idx, 1); // 2nd parameter means remove one item only
          }
        }
        return {
          success: res.data.success,
          message: res.data.message
        }
      // }
    },
    setAdmin(data) {
      console.log('setAdmin', {
        data,
        ' this.admin': this.admin,
      })
      if (this.admin) {
        console.log('Admin already Loaded')
      } else {
        this.admin = data
      }
    },
    async getUser(id) {
      if (this.isUserUpdated) {
        const res = await repository.show('users', id)
        res.data.data[0]['messages'].sort((a, b) => {
          console.log(a.createdAt, b.createdAt)
          let da, db
          if (a) da = new Date(a.createdAt)
          else da = 0

          if (b) db = new Date(b.createdAt)
          else db = 0
          // let da = new Date(a.messages[0] ? a.messages[0].createdAt : ''),
          //   db = new Date(b.messages[0] ? b.messages[0].createdAt : '');
          return da - db
        })
        this.user = res.data.data[0]
        console.log({ res: res.data.data[0] })

        if (this.userList) {
          const idx = this.userList['whatsapp'].findIndex((x) => x.id == this.user.id)

          if(this.user.messages.length > 0)
          this.userList['whatsapp'][idx]['messages'] = [
            this.user.messages[this.user.messages.length - 1],
          ];
          else 
          this.userList['whatsapp'][idx]['messages'] = []
        }

        this.isUserUpdated = false
      }
    },
    async getAdmin() {
      if (!this.admin) {
        const res = await repository.index('users/get-admin');
        console.log({
          resA: res.data.data[0]
        });

        this.admin = res?.data?.data[0]
      }
    },
    async addUser(data, platform) {
      // this.sortUsers();

      this.userList[platform].push(data)

      this.userList[platform].sort((a, b) => {
        console.log(a.messages[0], b.messages[0])
        let da, db
        if (a.messages[0]) da = new Date(a.messages[0].createdAt)
        else da = 0

        if (b.messages[0]) db = new Date(b.messages[0].createdAt)
        else db = 0
        // let da = new Date(a.messages[0] ? a.messages[0].createdAt : ''),
        //   db = new Date(b.messages[0] ? b.messages[0].createdAt : '');
        return db - da
      })
    },
    setIsUsersUpdated() {
      this.isUsersUpdated = true
    },
    resetIsUsersUpdated() {
      this.isUsersUpdated = false
    },
    setIsUserUpdated() {
      this.isUserUpdated = true
    },
    resetIsUserUpdated() {
      this.isUserUpdated = false
    },
    // manage converdations
    async sendMessage(message, platform) {
      console.log({
        message, platform,
        userList: this.userList
      })
      this.user['messages'].push(message)
      const idx = this.userList[platform].findIndex((x) => x.id == message.to)
      this.userList[platform][idx]['messages'] = [message]

      this.userList[platform].sort((a, b) => {
        console.log(a.messages[0], b.messages[0])
        let da, db
        if (a.messages[0]) da = new Date(a.messages[0].createdAt)
        else da = 0

        if (b.messages[0]) db = new Date(b.messages[0].createdAt)
        else db = 0
        // let da = new Date(a.messages[0] ? a.messages[0].createdAt : ''),
        //   db = new Date(b.messages[0] ? b.messages[0].createdAt : '');
        return db - da
      })

      const res = await repository.create('messages', message)
      console.log('sendMessage', { res })
    },

    async sendTemplateMessage(message, platform) {
      const res = await repository.create('messages', message);
      console.log('sendTemplateMessage', { res, message })
      let idx
      if(this.userList){
        message.users.forEach(user => {
          if( user.id){
             idx = this.userList[platform].findIndex((x) => x._id == user.id)
          }else{
             idx = this.userList[platform].findIndex((x) => x._id == user.id)
          }
          if(idx > -1)
            this.userList[platform][idx]['messages'] = [message]
        })
       
  
        this.userList[platform].sort((a, b) => {
          console.log(a.messages[0], b.messages[0])
          let da, db
          if (a.messages[0]) da = new Date(a.messages[0].createdAt)
          else da = 0
  
          if (b.messages[0]) db = new Date(b.messages[0].createdAt)
          else db = 0
          // let da = new Date(a.messages[0] ? a.messages[0].createdAt : ''),
          //   db = new Date(b.messages[0] ? b.messages[0].createdAt : '');
          return db - da
        })
      }
      
    },
    async getMessages(platform, id, page, limit) {
      const res = await repository.show('users', id, page, limit)
      if (res.data.data[0].messages.length > 0) {
        this.user.messages.unshift(...res.data.data[0].messages)
        const idx = this.userList[platform].findIndex((x) => x.id == id)
        this.userList[platform][idx]['messages'] = [
          this.user.messages[this.user.messages.length - 1],
        ]
        console.log('getMessages', { res: res.data.data[0].messages, userMgs: this.user.messages })
      }else{
          this.isUserHasMoreData = false;
      }
    },
    async receiveMessage(data, platform) {
      const idx = this.userList[platform].findIndex((x) => x.id == data.id)
      console.log('addMessageOnTop', { idx, to: data.id, platform })

      if(this.userList && idx > -1){
        this.userList[platform][idx]['messages'] = [data.messages[0]]
      }else{
        //new user
        this.userList[platform].push(data)
      }

      this.userList[platform].sort((a, b) => {
        console.log(a.messages[0], b.messages[0])
        let da, db
        if (a.messages[0]) da = new Date(a.messages[0].createdAt)
        else da = 0

        if (b.messages[0]) db = new Date(b.messages[0].createdAt)
        else db = 0
        // let da = new Date(a.messages[0] ? a.messages[0].createdAt : ''),
        //   db = new Date(b.messages[0] ? b.messages[0].createdAt : '');
        return db - da
      })
     
    },
    async updateMessage(id, data) {
      const res = await repository.update('messages', id, data)
      console.log('sendMessage', { res })
    },
    async addMessageToUser(data) {
      this.user['messages'].push(data.messages[0])
    },

    async sendAttachment(form_data, files, msgData, platform) {


      console.log({
        msgData
      })
      msgData.forEach((message) => {
        this.user['messages'].push(message)
        const idx = this.userList[platform].findIndex((x) => x.id == message.to)
        this.userList[platform][idx]['messages'] = [message]
  
        this.userList[platform].sort((a, b) => {
          console.log(a.messages[0], b.messages[0])
          let da, db
          if (a.messages[0]) da = new Date(a.messages[0].createdAt)
          else da = 0
  
          if (b.messages[0]) db = new Date(b.messages[0].createdAt)
          else db = 0
          // let da = new Date(a.messages[0] ? a.messages[0].createdAt : ''),
          //   db = new Date(b.messages[0] ? b.messages[0].createdAt : '');
          return db - da
        })
  
      })
     
      const config = {
        maxBodyLength: Infinity,
        keepExtensions: true,
        headers: {
          'content-type': 'multipart/form-data',
        },
      }

      const res = await repository.postWithConfig('messages/attachment', form_data, config)

      console.log('sendFilesHandler ==> ', { res })

      this.user['messages'].forEach((message) => {
        res.data.data.forEach((msg) => {
          if(message.client_id === msg.client_id){
            console.log({
              message,
              client_id: message.client_id
            })
            message.content = msg.path
          }
        })
      })

      // if (this.user) {
      //   for (let i = 0; i < files.length; i++) {
      //     this.user['messages'].forEach((msg) => {
      //       if (msg.idx === i) {
      //         console.log('< ========= >', {
      //           msg,
      //           i,
      //         })
      //         msg._id = res.data.data[i]._id
      //         msg.idx = ''
      //       }
      //     })
      //   }
      // }
    },

    async gerUsersFrom (from, isCampaigns = false){
      let res;
      if(!isCampaigns){
        res = await repository.addedFrom(from);
      }
       
      else{
        console.log({from});
        res = await repository.campaignsFilter(from);
      }
        

      console.log({
        res
      })
      
      return res.data.data;
    },
    async search (value) {
      this.searchValue = value;


        const res = await repository.paginate('users', 1,  100, value);

        console.log({
          res
        });
         
          res.data.data.users['whatsapp'].sort((a, b) => {
            console.log(a.messages[0], b.messages[0])
            let da, db
            if (a.messages[0]) da = new Date(a.messages[0].createdAt)
            else da = 0
  
            if (b.messages[0]) db = new Date(b.messages[0].createdAt)
            else db = 0
            // let da = new Date(a.messages[0] ? a.messages[0].createdAt : ''),
            //   db = new Date(b.messages[0] ? b.messages[0].createdAt : '');
            return db - da
          })
          // if (this.user && this.userList) {
          //   const idx = this.userList
          //     ? this.userList['whatsapp'].findIndex((x) => x.id == this.user.id)
          //     : null
  
          //   this.userList['whatsapp'][idx]['messages'] = [
          //     this.user.messages[this.user.messages.length - 1],
          //   ]
          // }
          console.log({
            data: res?.data?.data,
            userList: this.userList,
            totalCount: res.data.data.totalCount,
            is: res.data.data.totalCount > 0
          });

          if(res.data.data.totalCount > 0){
            
            this.userList = res.data.data.users;
            this.totalCount = res?.data?.data?.totalCount;
            this.totalPages = res?.data?.data?.totalPages;
            this.currentPage = 1;
          }
    }
  },
})
