<template>
  <v-row no-gutters class="d-flex align-center justify-center" style="height: 100vh">
    <v-col class="d-flex align-center justify-center" cols ="12" sm="12" md="4" lg="4" xl="4">
      <img class="bg-white" width="250" :aspect-ratio="1" src="../../assets/logo.jpg" cover />
    </v-col>

    <v-col  cols ="8" sm="8" md="4" lg="4" xl="4">
      <v-sheet>
        <v-alert class="mb-3" v-if="errorMsg" :text="errorMsg" type="error"></v-alert>
        <v-form fast-fail @submit.prevent="login">
          <!-- <v-text-field v-model="username"  required label="User Name"></v-text-field>

        <v-text-field v-model="password" label="password"></v-text-field> -->

          <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
            Username
          </div>
          <v-text-field density="compact" placeholder="User Name" v-model="username"
            prepend-inner-icon="mdi-account-outline" variant="outlined"></v-text-field>

          <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
            Password
          </div>

          <v-text-field :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" :type="visible ? 'text' : 'password'"
            density="compact" v-model="password" placeholder="Enter your password" prepend-inner-icon="mdi-lock-outline"
            variant="outlined" @click:append-inner="visible = !visible"></v-text-field>
          <!-- <a href="#" class="text-body-2 font-weight-regular">Forgot Password?</a> -->

          <v-btn type="submit" color="rgb(237 121 48)" block class="mt-2 text-white">log in</v-btn>
        </v-form>
        <!-- <div class="mt-2">
              <p class="text-body-2">Don't have an account? <a href="#">Sign Up</a></p>
          </div> -->
      </v-sheet>
    </v-col>

  </v-row>
</template>
<script>
import { mapActions } from 'pinia'
import { useUserStore } from '../../stores/user'
import { useGlobalStore } from '../../stores/global'

import auth from "../../api/auth";

export default {
  name: "LoginView",
  data: () => {
    return {
      errorMsg: "",
      username: "",
      password: "",
      visible: false,
      // baseApiUrl : import.meta.env.VITE_BACK_END_BASE_API_URL,
      // apiVersion : import.meta.env.VITE_BACK_END_BASE_API_VERSION,
    };
  },
  created() {
    document.title = 'Login';
  },
  methods: {
    ...mapActions(useUserStore, ['setAdmin']),
    ...mapActions(useGlobalStore, ['enableLoading', 'disableLoading']),

    async login() {
      const user = {
        username: this.username,
        password: this.password,
      };

      if (this.username && this.password) {
        try {
          this.enableLoading()
          let res = await auth.login(user);
          this.disableLoading()

          this.errorMsg = "";
          const maxAge = 3 * 60 * 60;

          console.log('login', { res })
          window.$cookies.set('jwt', res?.data?.data?.token, {
            httpOnly: true,
            maxAge: maxAge * 1000, // 3hrs in ms
          });

          this.setAdmin(res?.data?.data);

          await this.$router.push({ name: "home" });
        } catch (error) {
          console.log({ error })
          this.errorMsg = error.response?.data.message ?? error.message
        }
      }
    },
  },
};
</script>
